const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://6uidgcnl4m.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://rujwy1vev7.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.3',
    HOSTNAME: 'https://company.dev.wecomplai.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.dev.wecomplai.forwoodsafety.com',
    WEBSOCKET: 'wss://s9hvljacde.execute-api.us-west-2.amazonaws.com/dev'
  },
};

export default config;